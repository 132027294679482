
// Vocabulary
// A review is an exercise that gets sent to a user, maybe multiple times.
// A session is the user doing that exercise, completed or not, sometimes with duplicates.
// A time is the notification itself which gets sent to the user, and is unique.

// A time might have multiple sessions associated with it, but they share the same time id -
// this might happen if the user clicks the link twice in a message (completing the exercise is irrelevant).

import moment from '@/plugins/moment'

const PROGRESS = {
	NOT_STARTED: 0,
	IN_PROGRESS: 1,
	// 50% completion is sufficient; dokbot and many exercises \
	// will automatically have 100% progress on completion
	COMPLETE: 50
}

const STATUSES = {
  PLANNED: 1,
  TO_SEND: 2,
  SENT: 3,
  FAILED: 4
}

/**
 * List of reminders that do not require a response from the client.
 * These can be found in schedule.activity.title.
 */
const PASSIVE_ACTIVITIES = [
	'Encouragement',
	'Praise',
	'Confidence',
	'Support',
	'Attend next appointment'
]

const ASSESS_ACTIVITIES = [
	'Generalized Anxiety Disorder Screener (GAD-7)',
	'Penn State Worry Questionnaire for Children (PSWQ-C)',
	'Penn State Worry Questionnaire Adult (PSWQ-A)',
	'Screen for Child Anxiety Related Disorders Child Version',
	'Screen for Child Anxiety Related Disorders Parent Version',
	'Liebowitz Social Anxiety Scale Self-Report ',
	'Patient Health Questionnaire (PHQ-8)',
	'Center for Epidemiological Studies Depression Scale for Children',
	'Insomnia Severity Index (ISI)',
	'PTSD Checklist for DSM-5 (PCL-5)',
	'The Child PTSD Symptom Scale',
	'Caregiver Satisfaction Questionnaire',
	'Child/Adolescent Satisfaction Questionnaire',
	'Working Alliance Inventory - Short Revised - Client Version',
	'Child and Adolescent Disruptive Behavior Inventory - Parent',
	'Patient Health Questionnaire',
]


/**
 * Determines whether the activity is passive
 * (does the client interact with it).
 * @param {String} title    The activity title from schedule.activity.title.
 * @returns {Boolean}       Whether the activity is passive.
 */
export function activityIsPassive(title) {
	return PASSIVE_ACTIVITIES.includes(title)
}

/**
 * Determines whether the activity is an assessment
 * (does the client interact with it).
 * @param {String} title    The activity title from schedule.activity.title.
 * @returns {Boolean}       Whether the activity is an assessment.
 */
export function activityIsAssessment(title) {
	return ASSESS_ACTIVITIES.includes(title)
}

/**
 * Gets the total number of reminders that have been completed
 * for every scheduled set of one or more reminders.
 * This function is called for each 'review'
 * @param {Object} doc	The document from the current firestore snapshot.
 * @param {Date} targetDate The date to limit checking completion at (optional)
 * @returns {Number} 		The number of completed reminders from snapshot if > 0 else 0.
 */
export function getCompleteSessionCount(review, targetDate) {
	// Progress is an array of ints betweeen 0-100 for this one review entry

	let reviewProgress = review.sessions?.reduce((accum, session) => {
		if (!session.time) {
			console.error('Session in review has no time associated with it', session, review)
			return accum
		}

		// Skip over any sessions that occurred after targetDate, if set
		// session.createdAt.seconds is timestamp the session started regardless of if/when it finished
		if (targetDate && new Date(session.createdAt?.seconds ?? new Date()) > targetDate) {
			return accum
		}
		
		// Here's where it gets tricky
		// A user might start a session at the same time and not complete it at first
		// Later, the user might start the same session at the same time id and actually finish it
		// Therefore: we want to track the greatest progress value for any given session at any time id
		
		// Determine if we've already seen a session with this time id
		if (accum.some(s => s?.time === session.time)) {
			const existingSessionIdx = accum.findIndex(s => s?.time === session.time)
			const existingSession = accum[existingSessionIdx]
			if (existingSession.progress < session.progress) {
				// Replace less-complete session with this more-complete one
				accum[existingSessionIdx] = session
			}

			return accum
		}

		// New session at new time
		accum.push(session)
		return accum
	}, [])
	.map(session => session.progress)

	// Number of completed sessions in this review that are unique to each time id
	return reviewProgress?.filter(p => p >= PROGRESS.COMPLETE).length ?? 0
}


/**
 * Calculates the adherence rate given the number of complete reminders
 * and total reminders. Passive reminders, finished or not, are to be
 * filtered from both totals prior to this method.
 * @param {Number} complete	The number of completed reminders.
 * @param {Number} total 		The number of total reminders.
 * @returns {Number}				The calculated adherence rate if total > 0 else 0.
 */
export function calculateAdherenceRate(complete, total) {
	if (total > 0) return (complete / total) * 100
	return 0
}


/**
 * Gets the percent signed difference, or change, between two numbers.
 * @param {Number} fromValue	The initial (old) number.
 * @param {Number} toValue 		The final (new) number.
 * @returns {Number}					The percent change from the old number to the new number.
 */
export function getPercentageChange(fromValue, toValue) {
	if (fromValue > 0) return ((toValue - fromValue) / fromValue) * 100
	return 0
}


/**
 * Gets the number of days from a documents' converted 
 * Firestore timestamp since the current date.
 * @param {Object} timestamp	The Firestore timestamp object.
 * @returns {Number}					The number of days since timestamp.
 */
export function getDaysSinceDocument(timestamp) {
	return moment().diff(moment(timestamp?.toDate()), 'days')
}

/**
 * Counts the number of reminders in the provided review object (not snapshot) that have not
 * been sent yet. This is done by counting the TIMES in the review that have been sent.
 * @param {Object} review Single review object, not a firebase object
 * @param {Date} targetDate The date to limit checking sent/complete at (optional)
 * @returns Number of unsent reminders in the provided review snapshot
 */
export function getUnsentTimeCount(review, targetDate) {
	return review.times?.filter(time => {
		// TODO: removing isNotSent for now until we can update
		// sendStatus properly. Currently sendStatus is not updating when
		// the reminder is sent but only after the user follows the link
		// to the reminder
		// const isNotSent = time.sendStatus < STATUSES.SENT

		// Time logic similar to remindersTableFunctions.js
		const sendTime = moment(`
			${moment(time.nextAppointmentDate).format('MM DD YYYY')}
			${moment(time.nextAppointmentTime, 'HH:mm A').local().format('hh:mm:ss a')}`
		)
		let isScheduledForLater = sendTime > moment(Date.now())

		// Check if the send time would be after the targetDate if we're looking back in time
		if (targetDate && sendTime > moment(targetDate)) {
			isScheduledForLater = true
		}

		// Cancelled times have the signature:
		// isNotSent = true && isScheduledForLater = false

		// Times yet to send:
		// isNotSent = true && isScheduledForLater = true

		// Times that have been sent:
		// isNotSent = false && isScheduledForLater = false

		// Only occurs if we're checking unsentTimes as of targetDate:
		// isNotSent = maybe && isScheduledForLater = true

		// return (isNotSent || isScheduledForLater)
		return isScheduledForLater
	}).length ?? 0
}

/**
 * Counts the number of reminders in the provided review object (not snapshot)
 * that have failed to send. This is done by using the FAILED status constant.
 * @param {Object} review 
 * @returns Number of failed reminders provided by the review object
 */
export function getFailedTimeCount(review) {
  return review.times?.filter(time => {
    return time.sendStatus == STATUSES.FAILED
  }).length ?? 0
}

/**
 * Returns the number { complete, sent } of reviews in an array (not snapshot)
 * @param {Array} reviews Review documents as firebase objects
 * @param {Date} targetDate The date to limit checking sent/complete at (optional)
 * @returns 
 */
export function getCompleteAndSent(reviews, targetDate) {
	if (!(reviews instanceof Array)) {
		throw new Error(`Reviews must be an Array of documents`)
	}

	let countTotalTimes = 0
	let countCompleteSessions = 0
	let countFailedSessions = 0
	let countUnsentTimes = 0
	reviews.forEach(review => {
		// Passive reminders are reminders that don't require user interaction
		if (
			!activityIsPassive(review.data().schedule?.activity?.title) &&
			!activityIsAssessment(review.data().schedule?.activity?.title) &&
			!review.data().practice
		) {
			// Remember: sessions are directly correlated to times, but a time might have multiple sessions
			// This counts number of sessions per time 1:1 that have been completed, not total complete sessions
			// In other words, it only counts one complete session per time slot and prevents counting repeats
			// See function definition for more info
			countCompleteSessions += getCompleteSessionCount(review.data(), targetDate)
			countTotalTimes += review.data().times?.length ?? 0
			countFailedSessions += getFailedTimeCount(review)
			countUnsentTimes += getUnsentTimeCount(review.data(), targetDate)
		}

	})

	// Vars needed for calculating adherence accurately using times/sessions, not reminders
	// One could cancel all the factors in this equation but this is more intuitive to read:
	const sent = countTotalTimes - countFailedSessions - countUnsentTimes
	const incomplete = sent - countCompleteSessions
	const complete = sent - incomplete

	return { complete, sent }
}
