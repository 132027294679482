import moment from '@/plugins/moment' 
import getContact from '@/kit/helpers/contact'


/**
 * Converts lists of times and dates into a string
 * with grammatically-corrent conjunctions.
 * @param {Array} days 	The array of date strings from a reminder.
 * @param {Array} times The array of time strings from a reminder.
 * @returns {String}		Parsed schedule string.
 */
export function getScheduleString(days, times, activity) {
    let output = ''
		for (const i in days) {
			if (activity === 'Attend next appointment') {
				output += moment(days[i]).subtract(0, 'days').format('ddd (MMM D, YYYY)')
			} else {
				output += moment(days[i]).format('ddd (MMM D, YYYY)')
			}
			if (i == days.length - 2 && days.length == 2) {
				output += ' and '
			} else if (i < days.length - 2) {
				output += ', '
			} else if (i == days.length - 2) {
				output += ', and '
			}
		}
		output += ' at '
		for (const i in times) {
			output += moment.utc(times[i], 'HH:mm').local().format('h:mm a')
			if (i == times.length - 2 && times.length == 2) {
				output += ' and '
			} else if (i < times.length - 2) {
				output += ', '
			} else if (i == times.length - 2) {
				output += ', and '
			}
		}
	return output
}

/**
 * This function getDatesFormat is used to format dates and create a template as per future available dates
 * @param {Array} days 	The array of date strings from a reminder.
 * @param {String} activity Activity for which user has selected to set reminder.
 * @returns {String} output Template for cancel reminder dialog
 */
export function getDatesFormat(days, activity) {
  let output = ''
	let time = ''
		for (const i in days) {
			const subtract = activity === 'Attend next appointment' ? 1 : 0
			output += moment(days[i]).subtract(subtract, 'days').format('ddd (MMM D, YYYY) ')
			output +='at '
			output += days[i].utc(time, 'HH:mm').local().format('h:mm a')
			if (i == days.length - 2 && days.length == 2)
				output += ' and '
			else if (i < days.length - 2)
				output += ', '
			else if (i == days.length - 2)
				output += ', and '
		}
	
	return output
}

/**
 * Gets the time status of one or more reminder(s) from item.times.sendStatus.
 * Each item could have multiple reminders scheduled.
 * Send status refers to status of all reminders together.
 * Example:
 *   1 sent, 2 scheduled = scheduled
 *   1 sent, 1 scheduled, 1 failed = failed
 *   3 sent = sent
 * @param {Array} statusArr	Array of reminders per activitys' send statuses.
 * @returns {String} 				Send status.
 */
export function parseSendStatus(statusArr) {
	const SEND_STATUS = {
		PLANNED: 1,
		TO_SEND: 2,
		SENT: 3,
		FAILED: 4
	}
	if (statusArr.includes(SEND_STATUS.FAILED)) { return 'Failed' } 
	else if (statusArr.includes(SEND_STATUS.PLANNED) || statusArr.includes(SEND_STATUS.TO_SEND)) { return 'Scheduled' } 
	else if (statusArr.includes(SEND_STATUS.SENT)) { return 'Sent' }
	else { return 'ERROR' }
}


/**
 * Sets selectedReminder object from component state to 
 * current selected reminder from the reminders table
 * so that a brief overview of the reminder can be displayed
 * prior to cancellation.
 * @param {Object} cancelPreviewObj The selected reminder.
 * @param {Object} item 						The current item from component template slot.
 */
export function getSelectedReminder(cancelPreviewObj, item) {
	cancelPreviewObj.name = item.client.name
	cancelPreviewObj.contact = getContact(item.schedule)
	cancelPreviewObj.activity = item.schedule.activity.title
	let dates = getFutureDates(item.schedule.date, item.schedule.time)
	cancelPreviewObj.schedule = getDatesFormat(dates,item.schedule.activity.title)
}


/**
 * Checks if the selected reminders' latest reminder schedule
 * happend before the current time in order to check if the
 * reminder should be cancellable and change its status from
 * "scheduled" to "not started" or "sent".
 * @param {Object} item	The current item from template slot.
 * @returns {Boolean}		Whether the last scheduled time is old.
 */
export function reminderIsOutdated(item) {
	const date = item.schedule.date.slice(-1).pop()
	const time = item.schedule.time.slice(-1).pop()
	let lastScheduledTime = moment(`
	${moment(date).format('MM DD YYYY')}
	${moment.utc(time, 'HH:mm').local().format('hh:mm:ss a')}`)
	if (item.schedule.activity.title === 'Attend next appointment') {
		lastScheduledTime = lastScheduledTime.subtract(1, 'days')
	}
	return lastScheduledTime < moment(Date.now())
}

/**
 * This fucntion is used to filter the future dates which are yet to get reminders 
 * @param {Array} itemDate 	The array of date strings from a reminder.
 * @param {Array} itemTime Activity for which user has selected to set reminder.
 * @returns {Array} futureDates return array of unique future dates to cancel
 */
function getFutureDates(itemDate,itemTime){
	let futureDates = []
	var currDate = moment(Date.now())
	for (const i in itemDate) {
		const date = itemDate[i]
		for(const j in itemTime){
			const time = itemTime[j]
			let lastScheduledTime = moment(`
			${moment(date).format('MM DD YYYY')}
			${moment.utc(time, 'HH:mm').local().format('hh:mm:ss a')}`)
			if(lastScheduledTime > currDate){
			futureDates.push(lastScheduledTime)
			}	
		}	
	}     
	return futureDates
	
}


export default {
	getScheduleString,
	parseSendStatus,
	getSelectedReminder,
	reminderIsOutdated
}