export default function getContact(item) {
  const channel = item.channel

  if (!channel) {
    return ''
  }

  switch (channel.key) {
    case 'sms':
      return item.phone
    case 'email':
      return item.email
    default:
      return ''
  }
}
