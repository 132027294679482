<template>
  <dashboard-content full-width>
    <page-header sticky>
      <h1 
        v-if="haveChosenClient"
        class="mb-4" 
      >
        Reminders Summary for {{ client.name }}
      </h1>
      <h1
        v-else 
        class="mb-4"
      >
        Reminders Summary
      </h1>
    </page-header>
    <v-row class="wrapper">
      <v-col class="col-12 py-0">
        <firebase-table
          v-if="counterPath"
          :headers="headers"
          collection="reviews"
          :counter-path="counterPath"
          :counter-field="counterField"
          :prepared-collection="preparedCollection"
          sort-field="schedule.createdAt"
        >
          <!-- Scoped v-slot properties in use: 
            - Conditional contact method
            - Parse and format schedule string
            - Conditional parse of reminders status
            - Conditional visibility of action buttons
          -->

          <template v-slot:item.schedule.channel?.key="{ item }">
            <!-- Conditional contact method
              if key = 'sms', display scheduled item phone number
              if key = 'email', display scheduled item email address
            -->
            <span v-if="item.schedule.channel?.key === 'sms' || item.schedule.channel === 'sms'">{{ item.schedule.phone }}</span>
            <span v-if="item.schedule.channel?.key === 'email' || item.schedule.channel === 'email'">{{ item.schedule.email }}</span>
          </template>

          <template v-slot:item.schedule.activity.text="{ item }">
            <!-- Parse and format schedule string
              ddd (MM D, YYYY), ddd (MM D, YYYY) ... and ddd (MM D, YYYY)
              at h:mm a, h:mm a, ... and h:mm a
            -->
            {{ getScheduleString(item.schedule.date, item.schedule.time, item.schedule.activity.title) }}
          </template>

          <template v-slot:item.times="{ item }">
            <!-- Conditional parse of reminders status
              item.times.[].sendStatus 
              VALUE       | TABLE STRING
              --------------------------
              1 (planned) | Scheduled
              2 (to send) | Scheduled
              3 (sent)    | Sent
              4 (failed)  | Failed
            -->
            <!-- Reminder failed -->
            <span 
              v-if="
                item.scheduleRef !== null 
                  && parseSendStatus(item.times.map(x => x.sendStatus)) === 'Failed'"
            >
              <v-icon color="red">{{ mdiClose }}</v-icon> Failed
            </span>
            <!-- Reminder is set to "Sent" if sendStatus is 3, which
            happens when the client receives the reminder and starts it.
            This one is for non-passive reminders -->
            <span
              v-if="
                item.scheduleRef !== null
                  && parseSendStatus(item.times.map(x => x.sendStatus)) === 'Sent'"
            >
              <v-icon color="green">{{ mdiCheck }}</v-icon> Sent
            </span>
            <!-- Because passive reminders behave differently, the status
            of these reminders need to be set based on whether the schedule has
            been passed, since the sendStatus can't be set to 3 (sent) -->
            <span 
              v-else-if="
                item.scheduleRef !== null
                  && reminderIsOutdated(item) === true
                  && activityIsPassive(item.schedule.activity.title) === true"
            >
              <v-icon color="green">{{ mdiCheck }}</v-icon> Sent
            </span>
            <!-- Reminder is set to "Scheduled" if the sendStatus 1 or 2,
            when the client hasn't received the reminder yet -->
            <span 
              v-else-if="
                item.scheduleRef !== null 
                  && parseSendStatus(item.times.map(x => x.sendStatus)) === 'Scheduled'
                  && reminderIsOutdated(item) === false"
            >
              <v-icon color="yellow">{{ mdiClockOutline }}</v-icon> Scheduled
            </span>
            <!-- After client receives the reminder but hasn't started it yet, 
            the reminder is still set to Scheduled. If the reminder was already sent
            but not started, this slot will set to "Not started" --->
            <span 
              v-else-if="
                item.scheduleRef !== null
                  && parseSendStatus(item.times.map(x => x.sendStatus)) === 'Scheduled'
                  && reminderIsOutdated(item) === true"
            >
              <v-icon color="yellow">{{ mdiClockOutline }}</v-icon> Not started
            </span>
            <!-- If the reminder is cancelled by the user, it will delete 
            its corresponding schedule field and set the scheduleRef to null, 
            this can only happen if reminder hasn't already been send.
            Will set slot to "Cancelled" -->
            <span v-else-if="item.scheduleRef === null">
              <v-icon color="grey">{{ mdiClose }}</v-icon> Cancelled
            </span>
          </template>
          
          <template v-slot:item.action="{ item }">
            <!-- Item removal icon and action, active if reminder(s) not sent yet -->
            <span 
              v-if="
                item.scheduleRef !== null
                  && parseSendStatus(item.times.map(x => x.sendStatus)) === 'Scheduled'
                  && reminderIsOutdated(item) === false"
            >
              <span style="white-space: nowrap;">
                <v-icon 
                  small 
                  class="action-icon" 
                  @click="removeItem(item.id); 
                          getSelectedReminder(selectedReminder, item)"
                >
                  {{ mdiCloseCircleOutline }}
                </v-icon>
              </span>
            </span>
            <!-- Item removal icon is disabled if reminder(s) were already sent or failed -->
            <span v-else>
              <v-icon 
                small 
                disabled 
                class="action-icon-disabled"
              >
                {{ mdiCloseCircleOutline }}
              </v-icon>
            </span>
            <!-- Debug button for inspecting database fields for selected reminder -->
            <!-- <span 
              style="white-space: nowrap;"
            >
              <v-icon 
                small 
                class="action-icon" 
                @click="itemDebug(item)"
              >
                {{ mdiBugOutline }}
              </v-icon>
            </span> -->
          </template>
        </firebase-table>
      </v-col>

      <cancel-modal
        v-if="selectedItemId"
        :dialog="confirmDialog"
        :title="`Are you sure you want to cancel this reminder for ${selectedReminder.name}?`"
        :body="selectedReminder"
        @agree="remove"
        @disagree="confirmDialog = false"
      />
    </v-row>
  </dashboard-content>
</template>

<script>
import { mapGetters } from 'vuex'
import DashboardContent from '@/components/dashboard/DashboardContent'
import CancelModal from '@/components/CancelModal'
import FirebaseTable from '@/components/FirebaseTable'
import PageHeader from '@/components/dashboard/PageHeader'
import { 
  mdiEye, 
  mdiClose, 
  mdiCloseCircleOutline, 
  mdiClockOutline, 
  mdiCheck, 
  mdiSquareEditOutline,
  // mdiBugOutline
} from '@mdi/js'
import { 
  getScheduleString, 
  parseSendStatus, 
  getSelectedReminder, 
  reminderIsOutdated
} from '@/kit/helpers/remindersTableFunctions'
import { activityIsPassive } from '@/kit/helpers/summaryFunctions.js'

export default {
  components: {
    CancelModal,
    FirebaseTable,
    DashboardContent,
    PageHeader
  },
  data() {
    return {
      mdiEye,
      mdiClose,
      mdiCloseCircleOutline,
      mdiClockOutline,
      mdiCheck,
      mdiSquareEditOutline,
      // mdiBugOutline,
      getScheduleString,
      parseSendStatus,
      getSelectedReminder,
      reminderIsOutdated,
      activityIsPassive,
      headers: [
        { text: 'Name', value: 'client.name', width: '10%' },
        { text: 'Contact', value: 'schedule.channel.key' },
        { text: 'Reminder', value: 'schedule.activity.title' },
        { text: 'Scheduled for', value: 'schedule.activity.text' },
        { text: 'Status', value: 'times', width: '15%' },
        { text: '', value: 'action', sortable: false }
      ],
      confirmDialog: false,
      selectedItemId: null,
      preparedCollection: null,
      counterPath: null,
      counterField: null,
      selectedReminder: {
        name: '',
        contact: '',
        activity: '',
        schedule: ''
      }
    }
  },
  computed: {
    ...mapGetters('authentication', ['user', 'isProvider']),
    ...mapGetters('clients', ['client', 'haveChosenClient'])
  },
  created() {
    
    /**
     * Firestore query for filtering from reviews collection
     * for reminders by current user ID and client ID
     * if client is selected, or just user ID if client
     * is not selected.
     */
    if (this.haveChosenClient) {
      this.counterPath = `users/${this.user.id}`
      this.counterField = 'countReminders'
      this.preparedCollection = this.$firebase
        .firestore()
        .collection('reviews')
        .where('practice', '==', false)
        .where('createdBy', '==', this.user.id)
        .where('clientId', '==', this.client.id)
    } else {
      this.counterPath = `users/${this.user.id}`
      this.counterField = 'countReminders'
      this.preparedCollection = this.$firebase
        .firestore()
        .collection('reviews')
        .where('practice', '==', false)
        .where('createdBy', '==', this.user.id)
    }
  },
  methods: {
    // itemDebug(item) {
    //   /* To check the fields from the review collection for the exercise */
    //   console.log(item)

    //   /* To check the session as the exercise is in progress */
    //   // const session = item.sessions[0]
    //   // console.log(`Progress: ${session.progress}`)
    //   // console.log(`Time passed: ${session.timePassed}`)
    //   // console.log(`Duration: ${session.duration}`)
    // },
    removeItem(id) {
      this.selectedItemId = id
      this.confirmDialog = true
    },
    async remove() {
      try {
        await this.$firebase.firestore().collection('schedules')
          .doc(this.selectedItemId)
          .delete()
        this.confirmDialog = false
        this.$notify({
          text: 'Reminder successfully cancelled'
        })
      } catch (e) {
        this.confirmDialog = false
        console.error(e)
        this.$notify({
          type: 'err',
          text: 'Error occurred during cancellation'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.action-icon {
  cursor: pointer;
  opacity: 0.7;

  &:hover {
    opacity: 1.0;
  }

  &-disabled {
    opacity: 0.2;
  }
}
</style>