<template>
	<v-dialog
		v-model="dialog"
		:max-width="width"
	>
		<v-card class="pa-4">
			<v-card-title class="cancel-title mb-6 pt-1 justify-center">
				<h3 class="text-wrap word-break">{{ title }}</h3>
			</v-card-title>
			<v-list class="mb-6 pt-1 justify-center">
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title>Reminder</v-list-item-title>
						<v-list-item-subtitle class="text-wrap word-break">{{ body.activity }}</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title>Schedule</v-list-item-title>
						<v-list-item-subtitle class="text-wrap word-break">{{ body.schedule }}</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title>Contact</v-list-item-title>
						<v-list-item-subtitle class="text-wrap word-break">{{ body.contact }}</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<v-card-actions class="justify-space-around">
				<v-btn
					color="white"
					@click="disagree"
				>
					{{ dismissStr }}
				</v-btn>
				<v-btn
					color="primary"
					@click="agree"
				>
					{{ confirmStr }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		dialog: {
			type: Boolean,
			default: false
		},
		title: {
			type: String,
			default: 'Are you sure?'
		},
		body: {
			type: Object,
			default: () => ({})
		},
		width: {
			type: Number,
			default: 420
		},
		dismissStr: {
			type: String,
			default: 'Dismiss'
		},
		confirmStr: {
			type: String,
			default: 'Confirm'
		}
	},
	methods: {
		agree() {
			this.$emit('agree')
		},
		disagree() {
			this.$emit('disagree')
		}
	}
}
</script>

<style scoped>
	.text-wrap.word-break {
		display: inline-block;
		word-break: break-word;
	}
	.modal-title {
		word-wrap: break-word;
		justify-content: center;
	}
	.cancel-title {
		padding: 0px 16px 0px !important;
	}
</style>