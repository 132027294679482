var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dashboard-content',{attrs:{"full-width":""}},[_c('page-header',{attrs:{"sticky":""}},[(_vm.haveChosenClient)?_c('h1',{staticClass:"mb-4"},[_vm._v(" Reminders Summary for "+_vm._s(_vm.client.name)+" ")]):_c('h1',{staticClass:"mb-4"},[_vm._v(" Reminders Summary ")])]),_c('v-row',{staticClass:"wrapper"},[_c('v-col',{staticClass:"col-12 py-0"},[(_vm.counterPath)?_c('firebase-table',{attrs:{"headers":_vm.headers,"collection":"reviews","counter-path":_vm.counterPath,"counter-field":_vm.counterField,"prepared-collection":_vm.preparedCollection,"sort-field":"schedule.createdAt"},scopedSlots:_vm._u([{key:"item.schedule.channel?.key",fn:function({ item }){return [(item.schedule.channel?.key === 'sms' || item.schedule.channel === 'sms')?_c('span',[_vm._v(_vm._s(item.schedule.phone))]):_vm._e(),(item.schedule.channel?.key === 'email' || item.schedule.channel === 'email')?_c('span',[_vm._v(_vm._s(item.schedule.email))]):_vm._e()]}},{key:"item.schedule.activity.text",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getScheduleString(item.schedule.date, item.schedule.time, item.schedule.activity.title))+" ")]}},{key:"item.times",fn:function({ item }){return [(
              item.scheduleRef !== null 
                && _vm.parseSendStatus(item.times.map(x => x.sendStatus)) === 'Failed')?_c('span',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.mdiClose))]),_vm._v(" Failed ")],1):_vm._e(),(
              item.scheduleRef !== null
                && _vm.parseSendStatus(item.times.map(x => x.sendStatus)) === 'Sent')?_c('span',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v(_vm._s(_vm.mdiCheck))]),_vm._v(" Sent ")],1):(
              item.scheduleRef !== null
                && _vm.reminderIsOutdated(item) === true
                && _vm.activityIsPassive(item.schedule.activity.title) === true)?_c('span',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v(_vm._s(_vm.mdiCheck))]),_vm._v(" Sent ")],1):(
              item.scheduleRef !== null 
                && _vm.parseSendStatus(item.times.map(x => x.sendStatus)) === 'Scheduled'
                && _vm.reminderIsOutdated(item) === false)?_c('span',[_c('v-icon',{attrs:{"color":"yellow"}},[_vm._v(_vm._s(_vm.mdiClockOutline))]),_vm._v(" Scheduled ")],1):(
              item.scheduleRef !== null
                && _vm.parseSendStatus(item.times.map(x => x.sendStatus)) === 'Scheduled'
                && _vm.reminderIsOutdated(item) === true)?_c('span',[_c('v-icon',{attrs:{"color":"yellow"}},[_vm._v(_vm._s(_vm.mdiClockOutline))]),_vm._v(" Not started ")],1):(item.scheduleRef === null)?_c('span',[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v(_vm._s(_vm.mdiClose))]),_vm._v(" Cancelled ")],1):_vm._e()]}},{key:"item.action",fn:function({ item }){return [(
              item.scheduleRef !== null
                && _vm.parseSendStatus(item.times.map(x => x.sendStatus)) === 'Scheduled'
                && _vm.reminderIsOutdated(item) === false)?_c('span',[_c('span',{staticStyle:{"white-space":"nowrap"}},[_c('v-icon',{staticClass:"action-icon",attrs:{"small":""},on:{"click":function($event){_vm.removeItem(item.id); 
                        _vm.getSelectedReminder(_vm.selectedReminder, item)}}},[_vm._v(" "+_vm._s(_vm.mdiCloseCircleOutline)+" ")])],1)]):_c('span',[_c('v-icon',{staticClass:"action-icon-disabled",attrs:{"small":"","disabled":""}},[_vm._v(" "+_vm._s(_vm.mdiCloseCircleOutline)+" ")])],1)]}}],null,false,404351786)}):_vm._e()],1),(_vm.selectedItemId)?_c('cancel-modal',{attrs:{"dialog":_vm.confirmDialog,"title":`Are you sure you want to cancel this reminder for ${_vm.selectedReminder.name}?`,"body":_vm.selectedReminder},on:{"agree":_vm.remove,"disagree":function($event){_vm.confirmDialog = false}}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }